import { render, staticRenderFns } from "./Freemium.vue?vue&type=template&id=72995928&scoped=true&"
import script from "./Freemium.vue?vue&type=script&setup=true&lang=ts&"
export * from "./Freemium.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./Freemium.vue?vue&type=style&index=0&id=72995928&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72995928",
  null
  
)

export default component.exports